import request from '@/utils/request'

const agreementsApi = {
    // get agreement info
    base: '/api/v1/rest/agreements'
  }

export function getAgreement (params) {
    return request({
      url: agreementsApi.base + `/${params.id}`,
      method: 'get'
    })
}

export function getAgreementList (param) {
  let url = agreementsApi.base
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function postAgreement (data) {
  return request({
    url: agreementsApi.base,
    method: 'post',
    data
  })
}

export function delAgreement (params) {
  return request({
    url: agreementsApi.base + `/${params.id}`,
    method: 'delete'
  })
}

export function editAgreement (data) {
  return request({
    url: agreementsApi.base + `/${data.id}`,
    method: 'patch',
    data
  })
}
