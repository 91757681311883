<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
      
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import CreateForm from './modules/CreateForm'
import { getAgreementList } from '@/api/agreements'
const columns = [
  {
    title: '协议名称',
    dataIndex: 'title'
  },
  {
    title: '协议ID',
    dataIndex: 'id',
    sorter: true
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '关闭'
  },
  1: {
    status: 'processing',
    text: '运行中'
  },
  2: {
    status: 'success',
    text: '已上线'
  },
  3: {
    status: 'error',
    text: '异常'
  }
}

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm
  },
  data() {
    return {
      columns,
      visible: false,
      confirmLoading: false,
      mdl: null,
      totalCount: 0,
      advanced: false,
      queryParam: {},
      loadData: (parameter) => {
        const params = this.paramFormat(parameter)
        return getAgreementList(params).then(res => {
          const result = {
            data: res._embedded.agreements,
            pageNo: res.page.number,
            pageSize: res.page.size,
            totalCount: res.page.totalElements,
            totalPages: res.page.totalPages
          }
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleAdd() {
      this.mdl = null
      this.visible = true
      this.$router.push({
        path: '/systemSetup/agreement/edit'
      })
    },
    handleEdit(record) {
      const id = record.id
      this.$router.push({
        path: '/systemSetup/agreement/edit',
        query: {
          id
        }
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
</script>
